<template>
  <div class="pagination">
    <span class="pagination-count">
      {{ from }} - {{ to }} of {{ total }}
    </span>
    <multiselect v-model="pagination.perPage"
                 openDirection="bottom"
                 class="pagingSelect"
                 @select="changePerPage"
                 :options="[10, 20, 30, 50]"
                 :searchable="false"
                 :close-on-select="true"
                 :show-labels="false"
                 :tabindex="-1"
    />
    <div class="d-flex ms-2">
      <button class="btn btn-control me-2" @click="togglePage(previousPage)" :disabled="previousButtonIsDisabled">
        <img src="@/assets/icons/bold-icon previous.svg" alt="Previous">
      </button>
      <button class="btn btn-control" @click="togglePage(nextPage)" :disabled="nextButtonIsDisabled">
        <img src="@/assets/icons/bold-icon next.svg" alt="Next">
      </button>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: "Pagination",
  components: {Multiselect},
  props: {
    from: {type: Number, required: true},
    to: {type: Number, required: true},
    total: {type: Number, required: true},
    page: {type: Number, required: true},
    perPage: {type: Number, required:true}
  },
  data() {
    return {
      pagination: {
        from: 0,
        to: 0,
        total: 0,
        page: 1,
        perPage: 10
      },
    }
  },
  methods: {
    changePerPage(value) {
      this.pagination.perPage = value
      this.$emit('update', this.pagination)
    },
    togglePage(page) {
      this.pagination.page = page
      this.$emit('update', this.pagination)
    }
  },
  computed: {
    previousPage() {
      return this.pagination.page - 1
    },
    nextPage() {
      return this.pagination.page + 1
    },
    nextButtonIsDisabled() {
      return this.to === this.total
    },
    previousButtonIsDisabled() {
      return this.from <= 1
    },
  },
  watch: {
    from: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.pagination.from = newVal
      }
    },
    to: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.pagination.to = newVal
      }
    },
    total: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.pagination.total = newVal
      }
    },
    page: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.pagination.page = newVal
      }
    }
  },
  beforeMount() {
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .multiselect {
    &__single {
      width: fit-content;
    }

    &__select {
      padding: 0;
    }
  }
}

</style>
